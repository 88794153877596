.bundle-details-container {
    padding: 20px;
    margin-left: 300px;
    margin-top: 100px;
}

.bundle-details-container h2 {
    color: #333;
}

.bundle-products-table {
    width: 80%;
    border-collapse: collapse;
    margin-top: 20px;
}

.bundle-products-table th,
.bundle-products-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.bundle-products-table th {
    background-color: #1976d2;
    color: white;
}

.bundle-products-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.bundle-products-table tr:hover {
    background-color: #f1f1f1;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 20px;
}

.add-to-cart-button, .remove-from-cart-button {
    background-color: #1976d2;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    font-size: 16px;
}

.add-to-cart-button:hover, .remove-from-cart-button {
    background-color: #1976d2;
}

.cart-buttons{
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 20px;
    justify-content: flex-end;
}

.attribute-checkboxes {
    display: flex;
    gap: 10px;
}

.bundle-matter{
    font-size : 15px;
    text-align: center;
    font-weight: bold;
    margin-right: 20px;
    max-width: 80%; /* Ensures the note stays within the container width */
  word-wrap: break-word; /* Prevents text from overflowing */
  margin-bottom: 20px;
  text-align: justify; /* Optional: makes the note look more aligned */
}
.description-matter{
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
    max-height: 8;
    overflow-y: scroll;
    width: 300px;
    border: none;
    height: 180px;
}