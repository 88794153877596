.admin-products-page {
    display: flex;
    flex-direction: column;
}

.products-content {
    padding: 20px;
    margin-left: 300px;
    margin-top: 100px;
}

.add-product-button {
    margin-bottom: 20px;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.add-bundle-button {
    margin-bottom: 20px;
    padding: 10px 15px;
    background-color: #ff0022;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.add-product-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.add-bundle-button:hover {
    background-color: #d4001a; /* Darker shade on hover */
}

.products-grid,
.bundles-grid { /* Shared grid style for both products and bundles */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.product-card,
.bundle-card { /* Shared card style for both products and bundles */
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.product-card:hover,
.bundle-card:hover { /* Shared hover effect for both product and bundle cards */
    transform: translateY(-5px); /* Lift effect on hover */
}

.product-image,
.bundle-image { /* Shared image style for both products and bundles */
    width: 100%;
    height: 150px; /* Fixed height */
    object-fit: cover; /* Cover the space */
    border-radius: 10px;
}

.product-name,
.bundle-name { /* Shared name style for both products and bundles */
    font-size: 1.2em;
    margin: 10px 0;
    color: #333;
}

.product-description {
    font-size: 0.9em;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bundle-products { /* Style for the list of products inside a bundle */
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
}

.bundle-products h4 {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #333;
}

.product-price {
    font-size: 1.1em;
    color: #007bff;
    font-weight: bold;
    margin: 5px 0;
}

.product-category, 
.product-attributes {
    font-size: 0.8em;
    color: #888;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%; /* Make the width responsive */
    max-width: 500px; /* Set a max width */
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Scroll if content exceeds height */
    max-height: 90vh; /* Prevent modal from exceeding viewport height */
}

.modal-content h3 {
    margin-bottom: 20px;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.modal-content input,
.modal-content select {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
}

.modal-actions {
    display: flex;
    justify-content: space-between;
}

.modal-content button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

@media (max-width: 768px) {
    .modal-content {
        width: 95%;
        max-width: none;
    }
}


.modal-actions {
    display: flex;
    justify-content: space-between;
}

.remove-product-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.update-product-button {
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
    margin-right: 5px;
}

.remove-product-button:hover {
    background-color: darkred;
}

.visibility-settings {
    margin-top: 20px;
}

.visibility-settings label {
    display: block;
    margin: 10px 0;
}

.close {
    cursor: pointer;
    float: right;
}

.product-selection {
    margin-top: 10px;
}

.scrollable-product-list {
    max-height: 200px; /* Set the height to your desired maximum */
    overflow-y: auto; /* Enable vertical scrolling */
    border: 1px solid #ced4da; /* Optional: Add a border */
    padding: 10px; /* Optional: Add padding */
    border-radius: 5px; /* Optional: Add rounded corners */
}

.product-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.product-checkbox input.custom-checkbox {
    width: 30px; /* Width of the checkbox */
    height: 30px; /* Height of the checkbox */
    margin-right: 10px; /* Space between checkbox and label */
    cursor: pointer;
}

.remove-bundle-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}

.remove-bundle-button:hover {
    background-color: darkred;
}

.edit-bundle-button {
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 6px;
    transition: background-color 0.3s;
}

.edit-bundle-button:hover {
    background-color: rgb(12, 26, 219);
}

.confirm-yes {
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
    margin: 10px;
}

.confirm-no {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
}