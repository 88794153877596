*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f8f9fa; /* Light background for the entire page */
}

.cart-page {
    padding: 50px 20px;
    min-height: 100vh;
}

.back-button-container {
    position: absolute;
    top: 70px;
    right: 20px;
  }
  
  .back-button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

.orders-head {
    text-align: center;
    font-size: 2.5em;
    color: #343a40; /* Darker text for better contrast */
    margin-bottom: 30px;
    margin-top: 60px
}

.cart-items-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
}

.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px; 
    background: linear-gradient(to right, #ffffff, #e9ecef); /* Soft gradient for card */
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cart-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.cart-item-image {
    width: 120px;
    height: auto;
    border-radius: 8px;
    margin-right: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow for images */
}

.cart-item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column; 
    gap: 10px; 
}

.cart-item-name h3 {
    font-size: 1.5em;
    margin-bottom: 5px;
    color: #212529; /* Darker text color */
}

.cart-item-name p {
    font-size: 14px;
    color: #6c757d; /* Lighter text color for description */
}

.quantity-control {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
}

.quantity-control button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quantity-control button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.total-price {
    font-size: 16px;
    font-weight: bold;
    color: #28a745; /* Green color for prices */
}

.remove-button {
    padding: 10px 15px;
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.remove-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

.checkout-button {
    margin-top: 20px;
    padding: 12px 20px;
    font-size: 1.1em;
    color: white;
    background-color: #007bff; /* Bootstrap primary color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Full width for the button */
}

.checkout-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.cart-empty {
    text-align: center;
    font-size: 1.5em;
    color: #777;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px; /* More pronounced rounded corners */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    width: 300px;
    text-align: center;
}

.close-button {
    cursor: pointer;
    float: right;
    font-size: 1.5em;
    color: #aaa;
}

.close-button:hover {
    color: #000;
}

.subtotal-container {
    margin-top: 20px;
    font-size: 1em;
    color: #333;
    text-align: right;
}

.subtotal-container h3 {
    margin: 5px 0;
    font-weight: normal; 
}

.cart-item-price-points {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
