.location-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(145deg, #f0f4f8 0%, #d9e2ec 100%);
    padding: 20px;
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
}

.location-container {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(15px);
    border-radius: 25px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    padding: 50px;
    max-width: 500px;
    width: 100%;
    animation: slideIn 0.4s ease-out;
    border: 1px solid rgba(255, 255, 255, 0.2);
    max-height: 90vh; /* Limit height to viewport to prevent overflow */
    overflow-y: auto; /* Allow vertical scrolling within the container */
}

.home-location-matter {
    color: red;
    font-size: 16px;
    text-align: center;
}


/* Smooth Slide-in Animation */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Modern Form Styling */
.location-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #1f2937;
    font-family: 'Inter', sans-serif;
}

/* Header with Bold, Clean Typography */
.location-form h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 700;
    color: #334e68;
    letter-spacing: 0.5px;
}

/* Label Styling with Uppercase for Modern Look */
label {
    font-size: 14px;
    color: #7a869a;
    margin-bottom: 5px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.6px;
}

/* Neumorphic Inputs with a Smooth Hover and Focus Effect */
input,
select {
    margin-bottom: 20px;
    padding: 14px;
    border: none;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: inset 4px 4px 8px rgba(0, 0, 0, 0.05), inset -4px -4px 8px rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    font-size: 16px;
    color: #333;
}

input:focus,
select:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5), 0 0 20px rgba(0, 123, 255, 0.2);
    transform: scale(1.02);
}

/* Gradient Button with Elevation */
.submit-button {
    background: linear-gradient(135deg, #007bff 0%, #00d2ff 100%);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 14px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1), -5px -5px 20px rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
}

/* Button Hover with Lift Effect */
.submit-button:hover {
    background: linear-gradient(135deg, #0056b3 0%, #007bff 100%);
    box-shadow: 0 8px 25px rgba(0, 123, 255, 0.4);
    transform: translateY(-3px) scale(1.03);
}

.submit-button:active {
    transform: translateY(0px);
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
}

/* Success and Error Message Styling with Animation */
.success-message {
    color: #2ecc71;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
    animation: fadeIn 0.5s ease-in-out;
}

.error-message {
    color: #e74c3c;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
    animation: shake 0.3s ease-in-out;
}

/* Fade-in for Success Message */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Shake Animation for Error */
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    50% { transform: translateX(4px); }
    75% { transform: translateX(-4px); }
    100% { transform: translateX(0); }
}

/* Smooth Hover Transitions for Inputs */
input:hover,
select:hover {
    box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.1), inset -2px -2px 8px rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
    .location-container {
        width: 100%;
        padding: 30px;
        max-height: 80vh; /* Reduce max-height for smaller screens */
    }

    .location-form h2 {
        font-size: 28px;
    }

    input, select {
        font-size: 15px;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
