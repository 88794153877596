.admin-dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #e2e2e2 0%, #c3cfe2 100%);
    padding: 20px;
}

.admin-container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 20px;
    width: 400px;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.admin-container:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.3);
    transform: translateY(-3px);
}

.admin-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #333;
}

.admin-form h2 {
    font-size: 28px;
    font-weight: 600;
    color: #2e3a59;
    text-align: center;
    margin-bottom: 20px;
}

.cycle-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px; 
    margin: 20px 0;
}

.cycle-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px solid transparent; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cycle-card:hover {
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    transform: scale(1.05);
}

.cycle-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: border-color 0.3s;
}

.cycle-input:focus {
    border-color: #007bff;
    outline: none;
}

.button-container {
    display: flex;
    justify-content: center;
}

.submit-button {
    background: linear-gradient(135deg, #007bff 0%, #0656b0 100%);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background 0.3s, transform 0.2s ease, box-shadow 0.3s;
}

.submit-button:hover {
    background: linear-gradient(135deg, #0656b0 0%, #003d99 100%);
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.3);
}

.error-message {
    color: #e74c3c;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
