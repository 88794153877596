.filter-container {
    width: 250px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
}

.filter-container h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 20px;
}

.filter-item {
    margin-bottom: 20px;
}

.filter-item label {
    display: block;
    font-size: 16px;
    color: #555;
    margin-bottom: 8px;
}

.filter-item input,
.filter-item select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
}

.filter-item input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
}

.filter-item input:focus,
.filter-item select:focus {
    border-color: #1976d2;
}
