/* Adjust the main container to account for both side and top nav */
.page-container {
    display: flex;
    flex-direction: row; /* Flex for side nav and content */
    min-height: 100vh;
    padding-top: 60px; /* Height of the top nav */
    padding-left: 250px; /* Width of the side nav */
}

/* Content within the main page container */
.product-details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
}

/* Product details section */
.product-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

/* Product image styling */
.product-image {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
}

.pd-image {
    width: 100%;
    height: auto;
    max-width: 600px;
    object-fit: cover;
}

/* Product info section */
.product-detail-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 450px;
    margin-top: 20px;
}

/* Typography and button styles */
.product-detail-info h1 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
}

.product-detail-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #444;
}

.product-detail-info p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
}

.price {
    font-weight: bold;
    font-size: 22px;
    color: #1976d2;
    margin-bottom: 20px;
}

.add-to-cart-btn {
    padding: 12px 24px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
    background-color: #155c9b;
}

/* Custom attribute styles */
.custom-attributes {
    margin: 20px 0;
}

.custom-attributes h3 {
    margin-bottom: 10px;
}

.custom-attributes div {
    margin-bottom: 10px;
}

.custom-attributes input[type="radio"] {
    margin-right: 10px;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .product-details {
        flex-direction: row;
        gap: 50px;
        align-items: flex-start;
    }

    .product-detail-info {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .page-container {
        padding-left: 0; /* Remove left padding for side nav */
    }

    .product-detail-info h1 {
        font-size: 24px;
    }

    .price {
        font-size: 20px;
    }
}
