
.profile-page {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    min-height: 100vh; /* Full viewport height */
    background-color: #f4f4f4; /* Light gray background */
    padding: 20px;
    font-family: 'Roboto', sans-serif; /* Modern font */
}

.profile-container {
    width: 100%;
    max-width: 450px; /* Slightly wider for better layout */
    background-color: #ffffff; /* White background */
    padding: 30px;
    border-radius: 20px; /* More rounded corners */
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1); /* Deeper shadow for a modern look */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effect */
    text-align: center; /* Center text inside the container */
}

.profile-container:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.profile-title {
    font-size: 32px; /* Larger title for emphasis */
    margin-bottom: 20px;
    color: #2d3748; /* Dark gray */
    font-weight: 700; /* Bold title */
}

.loading-text {
    text-align: center;
    color: #999; /* Light gray */
    font-size: 18px; /* Larger loading text */
}

.profile-info {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Increased spacing for readability */
    font-size: 18px; /* Standard text size */
    color: #4a5568; /* Darker text color for better readability */
    text-align: start;
}

.profile-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between; /* Space between buttons */
    align-items: center; /* Align items vertically */
}

.profile-action-button {
    background-color: #e53e3e; 
    color: white; /* White text */
    transition: background-color 0.3s, transform 0.2s; /* Smooth color transition */
}

.profile-action-button:hover {
    background-color: #c53030; /* Darker red on hover */
    transform: scale(1.05); /* Scale effect on hover */
    color: #ffffff;
}

.profile-action-link {
    color: #3182ce; /* Blue color for links */
    text-decoration: none;  
    transition: color 0.3s, transform 0.2s; /* Smooth color transition and scale effect */
}

.profile-action-link:hover {
    color: #2b6cb0; /* Darker blue on hover */
    transform: scale(1.05); /* Scale effect on hover */
}

.separator {
    margin: 0 10px;
    color: #e53e3e; /* Separator color */
    font-weight: bold; /* Make the separator bold */
}

.success-message {
    color: #38a169; /* Green for success messages */
    margin-top: 10px;
    font-weight: 500; /* Medium weight */
}

.error {
    color: #e53e3e; /* Error color */
    margin-top: 10px;
}

/* Modal Styles */
.MuiDialog-root {
    font-family: 'Roboto', sans-serif; /* Consistent font in the modal */
}

.MuiDialogTitle-root {
    font-weight: 700; /* Bold title */
}

.MuiDialogContent-root {
    padding: 20px; /* Consistent padding in the dialog */
}

@media (max-width: 600px) {
    .profile-title {
        font-size: 28px; /* Slightly smaller title on mobile */
    }

    .profile-info {
        font-size: 16px; /* Smaller text size on mobile */
    }

    .profile-action-button,
    .profile-action-link {
        font-size: 14px; /* Smaller button and link text on mobile */
    }
}
