.users-page {
    display: flex;
    flex-direction: column;
}

.users-content {
    padding: 20px;
    margin-left: 300px;
    margin-top: 100px
}

.add-user-button {
    margin-bottom: 20px;
    padding: 10px 15px;
    margin-right: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.users-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.user-card {
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-word; /* Ensures long email addresses are wrapped */
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
}

.modal-content h3 {
    margin-bottom: 20px;
}

.modal-content form {
    display: flex;
    flex-direction: column;
}

.modal-content input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.modal-content button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.remove-user-button {
    margin-left: 10px;
    background-color: red; /* Change to your preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 16px;
}

.filter-dropdown {
    margin: 10px 0;
}

.filter-dropdown select {
    padding: 8px;
    margin-left: 5px;
    cursor: pointer;
}
