.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
}

/* Login Container with Glassmorphism */
.login-container {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    width: 360px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Fade In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.logo-matter {
    color: red;
    font-size: 12px;
    text-align: center;
}

/* Header Styling */
h2 {
    margin-bottom: 25px;
    font-size: 28px;
    color: #2e3a59;
    font-weight: 600;
    letter-spacing: 0.5px;
}

/* Login Form Styling */
.login-form {
    /* display: flex; */
    flex-direction: column;
    /* gap: 20px; */
}

.custom-label {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}

/* Input Fields */
.MuiInputBase-root {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
}

.MuiInputLabel-root {
    color: #333;
}

.MuiInputLabel-root.Mui-focused {
    color: #007bff;
}

.MuiOutlinedInput-root {
    border-radius: 12px;
}

.MuiOutlinedInput-root fieldset {
    border-color: rgba(0, 0, 0, 0.1);
}

.MuiOutlinedInput-root:hover fieldset {
    border-color: rgba(0, 0, 0, 0.2);
}

.MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #007bff;
}

/* Submit Button Styling */
.password-submit-button, .next-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

/* Button Hover Effects */
.password-submit-button:hover, .next-button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

/* Error Message Styling */
.error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: -10px;
    animation: shake 0.3s ease-in-out;
}

/* Shake Animation for Error */
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    50% { transform: translateX(4px); }
    75% { transform: translateX(-4px); }
    100% { transform: translateX(0); }
}

/* Password Field Styling */
.password-field {
    text-align: left;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
}

