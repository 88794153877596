.admin-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #343a40; /* Dark background for admin */
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1200;
}

.admin-navbar-title-menu {
    display: flex;
    align-items: center;
}

.admin-navbar-title {
    margin: 0;
    font-size: 24px;
}

.admin-navbar-menu-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 28px;
    color: white; /* White icon color */
}

.admin-sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    background-color: #fff;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start; /* Align items to the start */
}

.admin-sidenav-visible {
    left: 0;
}

.admin-sidenav-link {
    text-decoration: none;
    color: #333;
    padding: 15px 0;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
}

.admin-sidenav-link:hover {
    color: #007BFF; /* Change color on hover */
}

.admin-sidenav-icon {
    margin-right: 15px;
    font-size: 24px;
}

.admin-sidenav-logout {
    border: none;
    border-radius: 20px; /* Rounded edges */
    background: #ff4d4d; /* Logout button color */
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-top: auto; 
    padding: 10px; 
    text-align: center;
    transition: background-color 0.3s;
}

.admin-sidenav-logout:hover {
    background-color: #e60000; /* Darker shade on hover */
}

.close-admin-sidenav-button {
    background: none;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    font-size: 28px;
    color: #333; /* Color for close button */
    margin-bottom: 20px;
}

/* Overlay for Sidenav */
.admin-sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}
