/* ResetPassword.css */

.reset-password-page {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    background-color: #1e1e1e; /* Dark background */
}

.reset-password-container {
    display: flex; /* Side by side layout */
    background-color: #2e2e2e; /* Darker background for the container */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden; /* To prevent image overflow */
}

.reset-password-image {
    width: 400px; /* Fixed width for the image */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Cover the container */
}

.reset-password-form {
    padding: 40px; /* Padding for the form */
    color: white; /* Text color for form */
    display: flex; /* Use flexbox for form layout */
    flex-direction: column; /* Stack form elements vertically */
    justify-content: center; 
    width: 100%;
    max-width: 400px; 
    
}

.reset-password-form h2 {
    margin-bottom: 20px; /* Space below heading */
}

.reset-password-form .success {
    color: #4caf50; /* Green color for success messages */
}

.reset-password-form .error {
    color: red; /* Red color for error messages */
}

/* MUI Components Customizations */
.MuiOutlinedInput-root {
    color: white; /* Input text color */
}

.MuiInputLabel-root {
    color: white; /* Label color */
}

.MuiInputLabel-root.Mui-focused {
    color: white; /* Focused label color */
}

/* Additional media queries for responsive design */
@media (max-width: 768px) {
    .reset-password-container {
        flex-direction: column; /* Stack image above form on smaller screens */
    }
    
    .reset-password-image {
        width: 100%; /* Full width for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }
}
