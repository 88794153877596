.orders-page {
    padding: 60px 20px;
}

.orders-items-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 20px;
    max-width: 900px;
    margin: 0 auto;
}

.orders-head {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.orders-info-details{
    text-align: center;
}

.order-info {
    text-align: center;
    color: #1976d2;
    font-size: 1.1em;
    margin-bottom: 20px;
}

.order-item {
    display: flex;
    flex-direction: column; 
    padding: 15px;
    background-color: #fff; 
    border: 1px solid #ddd; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%; 
}

.order-id {
    color: #555; 
    margin-bottom: 10px; 
    font-weight: bold;
}

.item-count {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    border-radius: 50%;
    background-color: #1976d2; 
    color: white;
    text-align: center; 
    line-height: 24px; 
    margin-left: 10px; 
}

.orders-header {
    display: grid;
    grid-template-columns: 100px 1fr 80px; 
    margin-bottom: 10px; 
    font-weight: bold; 
    gap: 10px; 
    padding: 10px 50px 10px 0;
}

.order-item-details {
    display: grid; 
    grid-template-columns: 100px 1fr 80px; 
    align-items: center; 
    padding: 10px; 
    margin-bottom: 10px; 
    gap: 20px; 
    background-color: #f9f9f9; 
    border-radius: 4px; 
}

.order-item-image {
    width: 200%; 
    height: auto;
    border-radius: 4px;
}

/* .item-info {
    display: contents; 
} */
 .item-info {
    margin-left: 100px;
 }

.order-subtotal {
    display: flex; 
    flex-direction: column;
    justify-content: flex-end; 
    margin-top: 10px; 
    font-size: 1em;
    color: #333;
    padding: 10px; 
    border-top: 1px solid #ddd;
}

.shipping-charges {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.shipping, .subtotal {
    margin-left: auto; 
}

.subtotal {
    padding-top: 15px;
}

.orders-empty {
    margin-top: 20px;
    font-size: 1.2em;
    color: #777; 
    text-align: center;
}

.total-points {
    color: #1976d2;
}
