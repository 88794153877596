.location-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.location-container {
    display: flex;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    width: 400px;
    flex-direction: column;
}

.location-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: black;
}

.location-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.tile-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.location-tile {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    width: 120px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
}

.location-tile:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

.selected {
    border-color: #1976d2;
    background-color: #e3f2fd;
}

.submit-button {
    background-color: #ffedd5;
    color: black;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 16px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button:hover {
    background-color: #fed7aa;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
} 
/* Modern Page Styling */
.location-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
}

.location-container {
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-radius: 20px;
    width: 400px;
    backdrop-filter: blur(10px);
    animation: fadeIn 0.5s ease;
    transition: box-shadow 0.3s ease;
}

.glass-effect {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.location-container:hover {
    box-shadow: 0 12px 35px rgba(0, 0, 0, 0.2);
}

/* Form Styling */
.location-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #333;
}

.location-form h2 {
    font-size: 26px;
    font-weight: 600;
    color: #2e3a59;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
}

/* Tile Container */
.tile-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 25px;
}

/* Location Tile Styling */
.location-tile {
    border: none;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    width: 45%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: linear-gradient(145deg, #e0e5ec, #ffffff);
    box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #ffffff;
    font-size: 18px;
    font-weight: 500;
    color: #495867;
    position: relative;
}

.location-tile:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.5);
    transition: opacity 0.3s;
}

.location-tile:hover {
    transform: translateY(-8px);
}

.location-tile.selected {
    background: linear-gradient(145deg, #007bff, #0656b0);
    color: white;
}

.location-tile.selected:after {
    opacity: 1;
}

/* Button Container */
.button-container {
    display: flex;
    justify-content: center;
}

/* Gradient Button */
.submit-button {
    background: linear-gradient(135deg, #007bff 0%, #0656b0 100%);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background 0.3s, transform 0.2s ease, box-shadow 0.3s;
    box-shadow: 0 6px 15px rgba(0, 123, 255, 0.2);
}

.submit-button:hover {
    background: linear-gradient(135deg, #0656b0 0%, #003d99 100%);
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.3);
}

/* Error Message */
.error-message {
    color: #e74c3c;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
