.delivery-location-page {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    height: 100vh; 
    position: relative; 
}

/* Scrolling Text */
.scrolling-text {
    font-size: 1.5em;
    color: #1976d2;
    margin-bottom: 20px;
    animation: scrollText 8s linear infinite;
}

@keyframes scrollText {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.delivery-location-container {
    display: flex;
    /* flex-direction: row-reverse  ; */
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    max-width: 900px;
}

.delivery-image {
    max-width: 200px;
    margin-right: 30px;
}

.delivery-location {
    max-width: 500px;
    text-align: left;
}

.delivery-location h2 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
}

.delivery-location p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
}

.delivery-location input[type="radio"] {
    margin-right: 10px;
}

.delivery-location label {
    font-size: 1.2em;
    color: #333;
    /* margin-bottom: 10px; */
}

.delivery-location input[type="text"],
.delivery-location select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.delivery-location button {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.delivery-location button:hover {
    background-color: #1565c0;
}

/* Animations */
.animate-fade-in {
    opacity: 0;
    animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.animate-slide-in {
    transform: translateX(-30px);
    animation: slideIn 1s forwards;
}

@keyframes slideIn {
    from { transform: translateX(-30px); }
    to { transform: translateX(0); }
}

/* Caption Styles */
.caption {
    margin-top: 5px;
    font-size: 0.9em;
    color: #555;
    font-style: italic;
    transition: color 0.3s ease;
}

.caption:hover {
    color: #1976d2;
}
