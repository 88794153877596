.checkout-page {
    padding: 100px;
    max-width: 600px;
    margin: 0 auto;
}

.checkout-steps {
    display: flex;
    justify-content: center;
    margin-bottom: 20px 0;
}

.active-step {
    font-weight: bold;
    color: #1976d2; 
}

.checkout-content {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
}

.checkout-content h2 {
    margin-bottom: 20px;
}

.checkout-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.checkout-content button {
    padding: 10px 20px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-content button:hover {
    background-color: #1565c0;
}

.confirmation {
    margin-top: 20px;
}

.invoice {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.invoice h3 {
    margin: 10px 0;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-table th,
.invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.invoice-table th {
    background-color: #f2f2f2;
}

.checkout-steps {
    margin-bottom: 20px;
}

.checkout-content input {
    display: block;  /* Make inputs stack */
    margin: 10px 0;  /* Add space between inputs */
    width: 100%; /* Full width */
    padding: 10px; /* Add padding for better UX */
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-table th, .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.invoice-table th {
    background-color: #f4f4f4;
}

.location-toggle{
    display: flex;
    gap: 100px;
}

.location-choose {
    display: flex;
    gap: 100px;
}

.checkout-steps {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.step {
    width: 40px;
    height: 40px;
    border: 2px solid #1976d2; /* Border color */
    border-radius: 50%; /* Makes it circular */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    cursor: pointer;
    font-weight: bold;
    color: #1976d2; /* Default text color */
    transition: background-color 0.3s, color 0.3s;
}

.step-container {
    display: flex;
    flex-direction: column; /* Stack number and label vertically */
    align-items: center; /* Center align items */
    margin: 0 15px; /* Space between steps */
    cursor: pointer; /* Pointer cursor on hover */
}

.step-label {
    font-size: 14px; /* Adjust font size for labels */
    color: black; /* Color of labels */
}

.active-step {
    background-color: #1976d2; 
    color: white; 
}

.billing-matter{
    font-size : 10px;
    color: red;
    text-align: center;
}