.dashboard-container {
    display: flex;
    padding: 20px;
    margin-top: 50px;
}

.dashboard-content {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-direction: column;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(3,1fr); /* Adjusted to reduce card width */
    gap: 20px;
    flex-grow: 1;
    margin-left: 100px;
}

.product-heading {
    margin-left: 100px;
}

.bundles-heading {
    margin-left: 100px;
}

.product-list {
    column-gap: 0px; 
}

.product {
    border: 1px solid #ddd;
    border-radius: 18px;
    padding: 10px;
    background-color: white;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 350px; /* Set a maximum width for the product card */
    /* margin: 0 auto;  */
}

.separator {
    height: 2px; /* Height of the separator */
    background-color: #e0e0e0; /* Light gray color for the separator */
    margin: 10px 0; /* Margin for spacing */
}

/* Product info section */
.product-info {
    background-color: #f9f9f9; /* Light background for text area */
    padding: 10px; /* Padding for the text area */
    border-radius: 0 0 16px 16px; /* Rounded corners for the bottom */
}

.product:hover {
    transform: scale(1.05); 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
}

.product img {
    width: 100%; /* Ensures the image fills the full width */
    height: auto; /* Adjust height proportionally */
    max-height: 180px; /* Set max height to keep the card size consistent */
    object-fit: cover; /* Maintains aspect ratio while filling */
    margin-bottom: 10px; /* Reduced margin to fit the design */
    border-radius: 16px 16px 0 0; /* Rounded corners for a modern look */
}

.separator {
    height: 1px; /* Height of the separator */
    background-color: #e0e0e0; /* Light gray color for the separator */
    margin: 10px 0; /* Margin for spacing */
}

.product-info {
    background-color: #f9f9f9; /* Light background for text area */
    padding: 10px; /* Padding for the text area */
    border-radius: 0 0 16px 16px; /* Rounded corners for the bottom */
}

.product h3 {
    font-size: 18px;
    color: #333;
    margin: 0 0 5px 0; /* Adjusted margin for better spacing */
}

.product p {
    font-size: 14px;
    color: #777;
    margin: 5px 0; /* Adjusted margin for better spacing */
}

.product .price {
    font-size: 16px;
    color: #1976d2;
    font-weight: bold;
    margin: 5px 0; /* Adjusted margin for better spacing */
}

.product button {
    padding: 10px 15px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
}

.product button:hover {
    background-color: #1565c0;
}

.bundle button {
    padding: 10px 15px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    margin-top: 6px;
}

.bundle button:hover {
    background-color: #1565c0;
}

.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}

.success-message {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.dashboard-messages {
    margin-top: 60px;
}

.bundle-list {
    display: grid;
    grid-template-columns: repeat(3,1fr); 
    gap: 20px;
    margin-left: 100px;
}

.bundle {
    border: 1px solid #ddd;
    border-radius: 18px;
    padding: 10px;
    background-color: white;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    max-width: 350px;
}

.bundle img {
    width: 100%; /* Ensures the image fills the full width */
    height: auto; /* Adjust height proportionally */
    max-height: 180px; /* Set max height to keep the card size consistent */
    object-fit: cover; /* Maintains aspect ratio while filling */
    margin-bottom: 10px; /* Reduced margin to fit the design */
    border-radius: 16px 16px 0 0; /* Rounded corners for a modern look */
}

.bundle-info {
    padding: 10px;
}

.bundle-info h3 {
    font-size: 18px;
    /* margin: 0; */
    color: #333;
}

.bundle:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .bundle {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .bundle {
        width: 100%;
    }
}

.product-description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height to center vertically */
    width: 100%;   /* Full width to center horizontally */
  }
  
  
  .bundle-info {
    text-align: center;
  }
  
  .white-modal-container {
    position: fixed; 
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%); 
    /* background-color: #fff; */
    padding: 20px;
    height: 400px;
    width: 400px;
    z-index: 9999; 
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .bundle-modal-img {
    width: 200px;
    height: 100px;
  }
  
  .white-modal-container h4 {
    margin-top: 0;
  }
  
  .white-modal-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .white-modal-container ul li {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  body.modal-open {
    overflow-x: hidden;
    overflow-y: auto; 
  }
  
  .close-modal {
    align-self: flex-end;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  