.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: rgba(77, 77, 77, 1);
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.navbar-title-menu {
    display: flex;
    align-items: center;
}

.navbar-title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

/* Search Bar */
.navbar-search {
    display: flex;
    align-items: center;
    position: relative;
}

.navbar-search-input {
    padding: 10px 15px;
    border: none;
    border-radius: 25px; /* Rounded edges */
    font-size: 16px;
    width: 200px;
    transition: all 0.3s ease;
}

.navbar-search-input:focus {
    width: 250px;
    outline: none;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Light glow on focus */
}

.navbar-search-button {
    background: #ffb74d; /* Primary Color */
    color: white;
    border: none;
    border-radius: 25px; /* Rounded edges */
    padding: 10px 15px;
    margin-left: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.navbar-search-button:hover {
    background-color: #ffa726; /* Darker shade on hover */
    transform: scale(1.05); /* Slight grow effect */
}

.navbar-info {
    display: flex;
    align-items: center; 
}

.navbar-user-info {
    display: flex;
    align-items: center; 
    margin-right: 20px; 
}

.navbar-user-balance {
    margin-left: 5px; 
    font-size: 16px;
    color: #ffeb3b; /* Gold color for balance */
}

.navbar-cart-icon {
    position: relative;
    display: flex;
    align-items: center; 
}

.navbar-cart-count {
    background-color: #f44336; 
    color: white;
    border-radius: 50%;
    padding: 3px 7px;
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 12px;
}

.navbar-icon {
    color: white; 
    font-size: 28px; 
    margin-right: 10px; 
}

.navbar-menu-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 28px;
    display: flex;
    align-items: center;
}

/* Side Navigation Styles */
.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    background-color: white;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-between; 
}

.sidenav-visible {
    left: 0;
}

.sidenav-link {
    text-decoration: none;
    color: #333;
    padding: 15px 0;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease, padding-left 0.3s ease; /* Added padding transition */
}

.sidenav-link:hover {
    color: #007BFF; /* Primary color on hover */
    padding-left: 20px; /* Slide-in effect */
}

.sidenav-icon {
    margin-right: 15px;
    font-size: 24px; 
    color: #007BFF; /* Icon color */
}

.sidenav-links {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.sidenav-logout {
    border: none;
    border-radius: 20px; /* Rounded edges */
    background: #ff4d4d; /* Logout button color */
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-top: auto; 
    padding: 10px; 
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;
}

.sidenav-logout:hover {
    background-color: #e60000; /* Darker shade on hover */
    transform: translateY(-2px); /* Lift effect */
}

.close-sidenav-button {
    background: none;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    font-size: 28px;
    color: #333; /* Color for close button */
    margin-bottom: 20px;
}

/* Overlay for Sidenav */
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

/* Sticky Side Navigation */
.sticky-sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.2);
    z-index: 1100;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .navbar-title {
        font-size: 24px;
    }

    .navbar-search-input {
        width: 150px;
    }

    .navbar-cart-icon {
        margin-right: 10px;
    }

    .sidenav {
        width: 200px;
    }
}

.google-logo {
    width: 220px;
    margin-right: 15px;
}