/* Admin Orders Page */
.admin-orders-page {
    padding: 20px;
    margin-top: 100px; /* This accounts for the top navbar */
    padding-left: 250px; /* This accounts for the side navbar */
    font-family: 'Arial', sans-serif;
    color: #333;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
}

/* Responsive Orders Table */
.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.orders-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Add spacing below the row */
  }

/* Table Headers */
.orders-table th,
.orders-table td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.orders-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #555;
    text-transform: uppercase;
    font-size: 0.9rem;
}

/* Table Row Styling */
.orders-table td {
    font-size: 0.95rem;
    color: #333;
    vertical-align: middle;
}

.orders-table tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.2s ease;
}

/* Product List Styling */
.orders-table td div {
    font-size: 0.9rem;
    margin-bottom: 5px;
}

/* Error Message Styling */
.error-message {
    color: #ff5b5b;
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #ffecec;
    padding: 10px;
    border-radius: 8px;
}

.export-csv-button{
    height: 30px;
    width: 100px;
    color: white;
    background-color: #ff5b5b;
}

.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    gap: 15px;
  }
  
  .pagination-button {
    padding: 8px 16px;
    font-size: 14px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #0056b3;
  }
  
  .pagination-button.disabled,
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  
  .pagination-info strong {
    color: #007bff;
  }
  

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .admin-orders-page {
        padding-left: 0; /* Remove side padding for mobile */
        margin-top: 80px; /* Adjust top margin for smaller screens */
    }

    .orders-table th, .orders-table td {
        padding: 12px;
        font-size: 0.85rem;
    }
    
    .orders-table td {
        word-break: break-word;
    }
}

