@font-face {
  font-family: 'YourCustomFont';
  src: url('/public/fonts/Product Sans Regular.ttf') format('truetype'); 
  font-weight: bold;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'YourCustomFont', 'Arial Black', 'Arial Bold', Gadget, sans-serif; 
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}